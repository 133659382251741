<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- nome da campanha -->
      <template #cell(campaign)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.campaign }}
            </div>
          </div>
        </div>
      </template>

      <!-- tipo de campanha -->
      <template #cell(campaignType)="data">
        <div class="d-flex align-items-center">
          <!-- <b-avatar
            class="mr-1"
            :variant="data.item.campaignType === 'leadGenerate' ? 'light-info' : 'light-warning'"
          >
            <feather-icon
              size="18"
              :icon="data.item.campaignType === 'leadGenerate' ? 'UserIcon' : 'CodeIcon'"
            />
          </b-avatar>
          <span>{{ data.item.campaignType === "leadGenerate" ? 'Geração de lead' : 'Código de indicador' }}</span> -->
          <span>{{ data.item.campaignType === "voucher" ? 'Cupom' : 'Dinheiro' }}</span>
        </div>
      </template>

      <!-- leads -->
      <template #cell(leads)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.leads }}</span>
        </div>
      </template>

      <!-- vendas -->
      <template #cell(vendas)="data">
        <span class="font-weight-bolder mb-25">{{ data.item.qtdSales }}</span>
      </template>

      <!-- ticket médio -->
      <template #cell(ticket)="data">
        <span class="font-weight-bolder mb-25">{{ 'R$ ' +data.item.ticket.toFixed(2) }}</span>
      </template>

      <!-- conversion -->
      <template #cell(conversion)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.conversion+'%' }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
  // BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    // BAvatar,
    // BImg,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'campaign', label: 'CAMPANHA' },
        { key: 'campaignType', label: 'TIPO DE Recompensa' },
        { key: 'leads', label: 'LEADS' },
        { key: 'sells', label: 'VENDAS' },
        { key: 'ticket', label: 'TICKET MÉDIO' },
        { key: 'conversion', label: '% CONVERSÃO' },
      ],
    }
  },

}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
