<template>
  <section id="dashboard-ecommerce">
    <b-row class="mb-2">
      <b-col lg="4">
        <b-card-text>
          <span>Selecione uma campanha para filtrar o dashboard.</span>
        </b-card-text>

        <v-select
          v-model="selected"
          label="name"
          :options="option"
          :clearable="false"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <ecommerce-medal :data="congratulations" />
        </b-overlay>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <ecommerce-statistics :data="statisticsItems" />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col
            lg="12"
            md="6"
          >
            <b-overlay
              :show="isAPICallInProgress"
              rounded="sm"
            >
              <ecommerce-earnings-chart :data="engagement" />
            </b-overlay>
          </b-col>

          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <b-overlay
              :show="isAPICallInProgress"
              rounded="sm"
            >
              <statistic-card-with-area-chart
                v-if="indicators"
                icon="UsersIcon"
                :statistic="kFormatter(indicators)"
                statistic-title="Indicadores"
              />
            </b-overlay>

            <!-- <statistic-card-with-area-chart
              v-if="data.subscribersGained"
              icon="UsersIcon"
              :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
              statistic-title="Indicadores"
              :chart-data="data.subscribersGained.series"
            /> -->
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <b-overlay
              :show="isAPICallInProgress"
              rounded="sm"
            >
              <statistic-card-with-area-chart
                v-if="shareds"
                icon="CodeIcon"
                color="warning"
                :statistic="kFormatter(shareds)"
                statistic-title="Compartilhamentos"
              />
            </b-overlay>

            <!-- <statistic-card-with-area-chart
              v-if="data.ordersRecevied"
              icon="CodeIcon"
              color="warning"
              :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
              statistic-title="Compartilhamentos"
              :chart-data="data.ordersRecevied.series"
            /> -->
          </b-col>
        </b-row>
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <ecommerce-shared-methods :shared-methods="sharedMethods" />
        </b-overlay>
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <ecommerce-goal-overview :data="convertionPercentage" />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <ecommerce-company-table :table-data="dataTableDtos" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'

import {
  BRow, BCol, BCardText, BOverlay,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'

import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceSharedMethods from './EcommerceSharedMethods.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardText,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceEarningsChart,
    StatisticCardWithAreaChart,
    EcommerceCompanyTable,
    EcommerceSharedMethods,
    EcommerceGoalOverview,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      data: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      statisticsItems: [],
      sharedMethods: [],
      indicators: 0,
      shareds: 0,
      congratulations: {
        name: '',
        saleToday: '0',
      },
      convertionPercentage: {},
      engagement: [0],
      dataTableDtos: [],
      selected: { campaignToken: '', name: 'Todas as Campanhas' },
      option: [],
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  watch: {
    // monitorando a campanha selecionada
    selected(newCampaing) {
      this.getStatisticsItems({
        systemUrl: this.userData.SystemUrl,
        campaignToken: newCampaing.campaignToken,
      })
    },
  },
  created() {
    // data
    this.$http.get('/idq/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        // const userData = getUserData()
        // this.data.congratulations.name = userData.FullName.split(' ')[0] || userData.username
      })
    const userData = getUserData()
    this.congratulations.name = userData.FullName.split(' ')[0] || userData.username

    // recuperando a lista de campanhas
    this.getListCampaings()

    // chamando api do getCountViews
    this.getStatisticsItems({
      systemUrl: this.userData.SystemUrl,
      campaignToken: '',
    })
  },
  methods: {
    kFormatter,
    getListCampaings() {
      return new Promise((resolve, reject) => {
        useJwt.listCampaing()
          .then(response => {
            const campaingsFiltered = response.data
              .filter(campaing => campaing.status !== 'ARQUIVADA')
              .map(campaing => ({ campaignToken: campaing.token, name: campaing.name }))
            this.option = campaingsFiltered
            this.option.unshift({ campaignToken: '', name: 'Todas as Campanhas' })
            // console.log('campanhas: ', campaingsFiltered)
            return resolve(campaingsFiltered)
          })
          .catch(error => reject(error))
      })
    },
    getStatisticsItems(payload) {
      return new Promise((resolve, reject) => {
        useJwt.statisticsItems(payload)
          .then(response => {
            // console.log('res: ', response)

            // dados de faturamento
            this.congratulations.saleToday = response.data.amount.toString()

            // dados de resumo das campanhas
            this.statisticsItems = [
              {
                icon: 'EyeIcon',
                color: 'light-info',
                title: response.data.views,
                subtitle: 'Visualizações',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'MonitorIcon',
                color: 'light-primary',
                title: response.data.uniqueAccess,
                subtitle: 'Acessos únicos',
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'UserIcon',
                color: 'light-danger',
                title: response.data.leads,
                subtitle: 'Leads',
                customClass: 'mb-2 mb-sm-0',
              },
              {
                icon: 'AwardIcon',
                color: 'light-success',
                title: response.data.conversions,
                subtitle: 'Conversões',
                customClass: '',
              },
            ]

            // estatísticas dos métodos de compartilhamento
            this.sharedMethods = [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other]
            this.$store.commit('dashboard/SET_SHARED_METHODS_VALUES',
              [response.data.whatsApp, response.data.facebook, response.data.email, response.data.other])

            // dados de engajamento
            this.engagement = [response.data.engagement]

            // dados de conversão
            this.convertionPercentage = {
              lead: response.data.leads,
              conversions: response.data.conversions,
              series: [response.data.leadsConvertionPercentage],
            }

            // dados de indicadores e compartilhamentos
            this.indicators = response.data.indicators
            this.shareds = response.data.shareds

            // dados da tabela com detalhes das campanhas
            this.dataTableDtos = response.data.dataTableDtos

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
