<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Engajamento
        </b-card-title>
        <div class="font-small-2">
          Percentual de engajamento da sua campanha
        </div>
        <!-- <h5 class="mb-1">
          $4055.56
        </h5> -->
        <b-card-text class="text-muted font-small-2 mt-2">
          <p class="font-weight-bolder">
            • Leads x Acessos únicos
          </p>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          type="radialBar"
          height="120"
          :options="earningsChart.chartOptions"
          :series="data"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'radialBar',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          // comparedResult: [2, -3, 8],
          labels: [''],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          // plotOptions: {
          //   pie: {
          //     startAngle: 0,
          //     donut: {
          //       labels: {
          //         show: true,
          //         name: {
          //           offsetY: -150,
          //         },
          //         value: {
          //           offsetY: -100,
          //           formatter(val) {
          //             // eslint-disable-next-line radix
          //             return `${parseInt(val)}%`
          //           },
          //         },
          //         total: {
          //           show: true,
          //           offsetY: 0,
          //           label: '',
          //           formatter() {
          //             return '0%'
          //           },
          //         },
          //       },
          //     },
          //   },
          // },
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 15,
                size: '60%',
              },

              dataLabels: {
                showOn: 'always',
                name: {
                  offsetY: -150,
                  show: true,
                  color: '#888',
                  fontSize: '13px',
                },
                value: {
                  offsetY: -8,
                  color: '#111',
                  fontSize: '18px',
                  show: true,
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
