<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Meios de compartilhamento</b-card-title>
        <b-card-text class="font-small-2">
          Percentual de lead
        </b-card-text>
      </div>

      <!-- <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="(browser,index) in browserData"
        :key="browser.browserImg"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img
              :src="browser.browserImg"
              alt="browser img"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ browser.name }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <!-- <span class="font-weight-bold text-body-heading mr-1">{{ browser.usage }}</span> -->
          <!-- <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          /> -->
          <span class="font-weight-bold text-body-heading mr-1">{{ getChartSeries[index] }}%</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="[getChartSeries[index]]"
          />

        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    // BDropdown,
    // BDropdownItem,
    VueApexCharts,
  },
  props: {
    sharedMethods: {
      type: Array,
      default: () => [0, 0, 0, 0],
    },
  },
  data() {
    return {
      // teste: this.sharedMethods,
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: this.$store.getters['dashboard/getChartSeries'],
      // chartSeries: this.sharedMethods,
      // chartSeries: this.getChartSeries,
      aaa: this.$store.getters['dashboard/getChartSeries'],
      browserData: [
        {
          browserImg: require('@/assets/images/icons/whatsapp.png'),
          name: 'Whatsapp',
          // usage: `${this.sharedMethods[0]}%`,
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/facebook.png'),
          name: 'Facebook',
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/email.png'),
          name: 'Email',
          usage: '10%',
        },
        {
          browserImg: require('@/assets/images/icons/outros.png'),
          name: 'Outros',
          usage: '10%',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getChartSeries: 'dashboard/getChartSeries',
    }),
  },
  created() {
    for (let i = 0; i < this.browserData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }

    // setTimeout(() => {
    //   for (let i = 0; i < this.browserData.length; i += 1) {
    //     const chartClone = JSON.parse(JSON.stringify(this.chart))
    //     chartClone.options.colors[0] = this.chartColor[i]
    //     chartClone.series[0] = this.sharedMethods[i]
    //     console.log('props sharedMethods: ', this.sharedMethods)
    //     console.log('chartClone: ', chartClone)
    //     console.log('chartData series: ', this.chartData)
    //     this.chartData.push(chartClone)
    //   }
    // }, 3000)

    // setTimeout(() => {
    //   console.log('deu tempo')
    //   console.log('props metodos ', this.sharedMethods)
    //   console.log('data chartSeries', this.chartSeries)
    //   console.log(this.chartData)
    // }, 5000)
  },
}
</script>
